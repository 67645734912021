@import '../../bootstrap-4.3.1/scss/bootstrap';


body {
    font-family: $font-primary;
}

$color-pri: rgba(0,0,255,.5);
$bg-color2: #effcfd;
$brand-primary: rgb(46, 202, 219);
$brand-primary-overlay: linear-gradient(-50deg, rgba(46,202,219, .7) 40%, rgba(0,0,255, .7));
$brand-secondary: rgba(255, 84, 78, .7);
$brand-tertiary: rgb(182, 102, 228);


.navbar {
    background: rgba($color: #000000, $alpha: 0.7);
    padding: 15px 0;
    color: white;

    &-brand {
        color: white;
        font-weight: bold;

        &:hover {
            color: white;
        }
    }
    &__header {
        display: flex;
        width: 100%;
    }
    &__btn {
        margin-left: auto;
        border: solid 1px white;
        color: white;
        background: transparent;
    }
}

.portfolio-filter {
    width: 220px;
    margin-left: 10px;

    .btn {
        width: 100%;
        
    }
}



.header {
    background: $brand-primary-overlay, url('/site/images/working-desk.jpg') no-repeat center bottom;
    background-size: cover;
    min-height: 50vh;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
        min-height: 50vh;
    }
}

.rad-0 {
    border-radius: 0;
}

.profile-img {
    border-radius: 100%;
    margin: 30px 0;
    height: 250px;
    width: 200px;
}


.section {
    padding: 100px 0;
    
    &__title {
        font-family: $font-formal;
        margin: 30px auto;
        text-align: center;
    }

    &__hr {
        border-bottom: solid 5px $brand-primary;
        width: 10%;
        margin: auto;


    }

    .btn {
        border-radius: 0;
        margin: 15px 0;
        letter-spacing: 2px;
        // background: $brand-primary;
        border-color: $brand-primary;

        &:hover {
            background: white;
            border-color: white;
            color: $brand-primary;
        }
    }

    &--inverse {
        background: $brand-primary;
        color: white;

        .section__hr {
            border-bottom: solid 5px white;
        }
    }

    &-interests {
        @media (min-width: map-get($grid-breakpoints, 'lg')) {
            padding-top: 0;
        }
    }
}

.portfolio-filter {
    width: 220px;

    .btn {
        width: 100%;
    }
}

.card-body {
    padding-bottom: 0;
    position: relative;
    
}

.card-icons {
    margin-bottom: -30px;
}

.card-header,
.card-footer {
    background: $brand-primary;
    color: white;
}

.card-link {
    color: white;
    &:hover {
        color: white;
    }
}

.card-img {
    height: 50px;
    width: 50px;
}

.btn-on-dark-bg {
    background: transparent;
    outline: solid 2px white;
    color: white;
}


.scroll-snap-parent {
    scroll-snap-type: mandatory;
}

.scroll-snap-child {
    scroll-snap-align: start;
}

.interest {
    margin-bottom: 20px;

    &-title {
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
    }

    &__img {
        border-radius: 30px;
        box-shadow: 5px 5px 20px white;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {

        &-title {
        grid-column: 3 / span 1;
        grid-row: 2 / span 1;
        align-self: center;

            .section__hr {
                width: 80%;
            }
        }

        &-biking {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
        }

        &-development {
            grid-column: 4 / span 1;
            grid-row: 2 / span 1;
        }

        &-hiking {
            grid-column: 2 / span 1;
            grid-row: 2 / span 1;
        }

        &-darts {
            grid-column: 5 / span 1;
            grid-row: 1 / span 1;
        }

        &-pool {
            grid-column: 1 / span 1;
            grid-row: 3 / span 1;
        }
        
        &-boardgame {
            grid-column: 5 / span 1;
            grid-row: 3 / span 1;
        }
    }
}

.interests {
    
    @media (min-width: map-get($grid-breakpoints, 'lg')) {
        padding-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}

.portfolio-icons {
    position: relative;
    bottom: 0;
}

.btn.btn-on-dark-bg:disabled {
    background: white;
    color: $brand-primary;
}